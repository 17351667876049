<template>
	<el-dialog class="dialog" title="编辑" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="130px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户账号：" prop="accountNo">
							<el-input v-model="formData.accountNo"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="经办人：" prop="agentName">
							<el-input v-model="formData.agentName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="经办人身份证：" prop="agentIdCard">
							<el-input v-model="formData.agentIdCard"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="经办人手机：" prop="agentMobile">
							<el-input v-model="formData.agentMobile"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="账号权限：">
							<el-select v-model="formData.roleIds" multiple collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="企业名称：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false"
								placeholder="请选择" @change="selectEnt">
								<el-option v-for="item in ents" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="信用代码：" prop="creditCode">
							<el-input v-model="formData.creditCode"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="法人姓名：" prop="legalPersonName">
							<el-input v-model="formData.legalPersonName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="法人身份证：" prop="legalPersonIdCard">
							<el-input v-model="formData.legalPersonIdCard"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="所在城市：">
							<el-cascader v-model="provinces" clearable placeholder="请选择省/市/区" @change="CityChange"
								:props="{ value: 'name', label: 'name', children: 'child' }" :options="cityOptions">
							</el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="详细地址：">
							<el-input v-model="formData.address"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="images">
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:0,
							name:'businessLicenseImg' }">
						<el-image v-if="imgUrl[0]" :src="imgUrl[0]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:1,
							name:'legalPersonIdCardPosImg' }">
						<el-image v-if="imgUrl[1]" :src="imgUrl[1]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:2,
							name:'legalPersonIdCardNegImg' }">
						<el-image v-if="imgUrl[2]" :src="imgUrl[2]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:3,
							name:'entAuthLetterImg' }">
						<el-image v-if="imgUrl[3]" :src="imgUrl[3]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:4,
							name:'agentIdCardPosImg' }">
						<el-image v-if="imgUrl[4]" :src="imgUrl[4]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload" :data="{
							index:5,
							name:'agentIdCardNegImg' }">
						<el-image v-if="imgUrl[5]" :src="imgUrl[5]" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="账号状态：" prop="status" v-if="dialogObj.type!=0">
							<el-switch v-model="formData.status" active-color="#4A99F6" :active-value="1"
								:inactive-value="0">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				formData: {},
				newFile: new FormData(),
				imgUrl: [],
				roles: "",
				cityOptions: [],
				provinces: [],
				ents: [],
				roles: [],
				title: "",
				rules: {
					accountNo: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					agentName: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					entId: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					creditCode: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					legalPersonName: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					status: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					agentIdCard: {
						validator: (rule, value, callback) => {
							const IDCardReg =
								/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
							// const sfzhReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
							if (value) {
								if (IDCardReg.test(value)) {
									callback()
								} else {
									callback(new Error('身份证号格式不正确'))
								}
							} else {
								callback(new Error('请输入身份证号'))
							}
						},
						trigger: 'blur'
					},
					legalPersonIdCard: {
						validator: (rule, value, callback) => {
							const IDCardReg =
								/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
							// const sfzhReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
							if (value) {
								if (IDCardReg.test(value)) {
									callback()
								} else {
									callback(new Error('身份证号格式不正确'))
								}
							} else {
								callback(new Error('请输入身份证号'))
							}
						},
						trigger: 'blur'
					},
					agentMobile: {
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
							if (!value) {
								return callback(new Error('电话号码不能为空'))
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'))
								} else {
									if (phoneReg.test(value)) {
										callback()
									} else {
										callback(new Error('电话号码格式不正确'))
									}
								}
							}, 100)
						},
						trigger: 'blur'
					},
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.imgUrl = [];
				this.roles = "";
				this.cityOptions = [];
				this.provinces = [];
				this.ents = [];
				this.roles = [];
				this.getEnts();
				this.get();
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			//获取企业列表
			getEnts() {
				this.$get("/user-api/qs_ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			selectEnt(val) {
				this.formData.roleIds = [];
				this.getRoles(val);
			},
			//获取账户角色
			getRoles(entId) {
				this.$get("/user-api/qs_role/list", {
					entId: entId,
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.roles = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//获取数据
			get() {
				this.$get("/user-api/qs/user/" + this.dialogObj.id, null).then((res) => {
					if (res.code == 1000) {
						this.selectEnt(res.data.entId);
						this.formData = res.data;
						this.getProvince(1);
						//图片回显
						this.imgUrl.push(configApi.photoURL + this.formData.businessLicenseImg);
						this.imgUrl.push(configApi.photoURL + this.formData.legalPersonIdCardPosImg);
						this.imgUrl.push(configApi.photoURL + this.formData.legalPersonIdCardNegImg);
						this.imgUrl.push(configApi.photoURL + this.formData.entAuthLetterImg);
						this.imgUrl.push(configApi.photoURL + this.formData.agentIdCardPosImg);
						this.imgUrl.push(configApi.photoURL + this.formData.agentIdCardNegImg);
						//账号权限回显
						this.formData.roleIds = [];
						for (let item of this.formData.roles) {
							this.formData.roleIds.push(item.id);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
					this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
				} else if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				} else {
					this.currentFileName = file.name;
					if (file) {
						if(this.newFile.has("file")){
							this.newFile.delete("file");
						}
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			//手动上传
			Upload(param) {
				this.loading = true;
				this.$upload(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.formData[param.data.name] = res.data
						this.imgUrl[param.data.index] = configApi.photoURL + res.data;
						this.loading = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			// 获取省市区地址
			getProvince(e) {
				// 获取省市区地址
				this.$get("base-api/area/tree").then((data) => {
					this.cityOptions = this.getTreeData(data.data);
					if (e == 1) {
						this.$nextTick(() => {
							let i, j, k;
							for (i = 0; i < this.cityOptions.length; i++) {
								if (this.cityOptions[i].name === this.formData.province) {
									this.$set(this.provinces, 0, this.cityOptions[i].name);
									break;
								}
							}
							for (j = 0; j < this.cityOptions[i].child.length; j++) {
								if (this.cityOptions[i].child[j].name === this.formData.city) {
									this.$set(this.provinces, 1, this.cityOptions[i].child[j].name);
									break;
								}
							}
							for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
								if (
									this.cityOptions[i].child[j].child[k].name === this.formData.district
								) {
									this.$set(this.provinces, 2, this.cityOptions[i].child[j].child[k]
										.name);
									break;
								}
							}
						});
					}
				});
			},
			// 递归判断列表，把最后的children设为undefined
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].child.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].child = undefined;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].child);
					}
				}
				return data;
			},
			CityChange(value) {
				this.formData.province = value[0];
				this.formData.city = value[1];
				this.formData.district = value[2];
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/user-api/qs/user/update", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
